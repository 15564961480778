<template>
    <div
        class="vimeo-video"
        :id="'vimeo-video--' + videoId"
        :class="[
            isBackgroundVideo ? 'vimeo-video--bg' : '',
            !isPaused ? 'vimeo-video--is-playing' : '',
        ]"
    >
        <div class="vimeo-video__container">
            <div
                v-if="poster"
                :style="{ backgroundImage: `url(${poster})` }"
                class="vimeo-video__poster"
                v-show="showPoster"
            ></div>
            <div
                class="vimeo-video__control-overlay"
                @click="playOrPauseVideo"
                @mouseover="togglePlayCursor"
                @mouseleave="toggleDefaultCursor"
                v-show="
                    !isBackgroundVideo ||
                    isPaused ||
                    (!isPaused && !showControlsByDefault)
                "
            >
                <div
                    class="vimeo-video__control-overlay-play-icon"
                    :class="[
                        isPaused
                            ? ''
                            : 'vimeo-video__control-overlay-play-icon--playing',
                        showPlayIcon
                            ? 'vimeo-video__control-overlay-play-icon--show'
                            : 'vimeo-video__control-overlay-play-icon--hide',
                    ]"
                >
                    <IconButton icon="play" filled="white" text="Play" />
                </div>
            </div>
            <div
                class="vimeo-video__controls"
                v-if="!showControlsByDefault && hasLoaded"
            >
                <IconButton
                    element="button"
                    :icon="isPaused ? 'play' : 'pause'"
                    color="white"
                    size="stretch"
                    filled="white"
                    @click="playOrPauseVideo"
                    class="vimeo-video__control vimeo-video__control-play"
                />
                <IconButton
                    element="button"
                    :icon="muted ? 'volume-off' : 'volume-on'"
                    color="white"
                    size="stretch"
                    filled="white"
                    @click="toggleSound"
                    class="vimeo-video__control vimeo-video__control-sound"
                />
            </div>
            <div
                :id="playerSelectorId"
                class="vimeo-video__player"
                :class="freeFlow ? 'vimeo-video__player--freeflow' : ''"
                @mouseover="togglePlayCursor"
                @mouseleave="cursorStore.setCursorType('default')"
            ></div>
        </div>
    </div>
</template>
<script setup>
import Player from '@vimeo/player'

let player

const props = defineProps({
    videoId: {
        required: true,
        type: String,
    },
    playerId: {
        default: '',
        type: String,
    },
    poster: {
        default: '',
        type: String,
    },
    freeFlow: {
        default: false,
        type: Boolean,
    },
    forcePlay: {
        default: false,
        type: Boolean,
    },
    noCursorControls: {
        default: false,
        type: Boolean,
    },
    loop: {
        default: false,
        type: Boolean,
    },
    showControlsByDefault: {
        default: false,
        type: Boolean,
    },
    showPlayIcon: {
        default: true,
        type: Boolean,
    },
    isBackgroundVideo: {
        type: Boolean,
        default: false,
    },
})

const emits = defineEmits(['play-video', 'paused', 'loaded'])

const cursorStore = useCursorStore()
const showPoster = ref(true)
const isPaused = ref(true)
const muted = ref(false)
const hasLoaded = ref(false)

onMounted(() => {
    player = new Player(playerSelectorId.value, {
        id: props.videoId,
        controls: props.showControlsByDefault && !props.isBackgroundVideo,
        loop: props.loop || props.isBackgroundVideo,
        background: props.isBackgroundVideo,
        dnt: true,
    })

    player.on('play', () => {
        isPaused.value = false
        showPoster.value = false
        togglePlayCursor()
    })

    player.on('pause', () => {
        isPaused.value = true
        togglePlayCursor()
    })

    player.on('loaded', () => {
        showPoster.value = false
        hasLoaded.value = true
        emits('loaded', true)
    })
})

function playOrPauseVideo() {
    player
        .getPaused()
        .then((paused) => {
            if (paused) {
                playVideo()
            } else {
                pauseVideo()
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

function playVideo() {
    player.play()
    isPaused.value = false
    togglePlayCursor()
    emits('play-video')
}

function pauseVideo(externallyPaused = false) {
    player.pause()
    isPaused.value = true

    if (!externallyPaused) {
        emits('paused', true)
    }

    togglePlayCursor()
}

function togglePlayCursor() {
    if (props.isBackgroundVideo) {
        return
    }

    if (hasLoaded.value === false) {
        cursorStore.setCursorType('default')
        return
    }

    if (props.noCursorControls && props.forcePlay) {
        cursorStore.setCursorType('close')
    } else if (
        props.noCursorControl &&
        !isPaused.value &&
        !props.isBackgroundVideo
    ) {
        cursorStore.setCursorType('hidden')
    } else if (!isPaused.value && !props.showControlsByDefault) {
        cursorStore.setCursorText('Pause')
        cursorStore.setCursorType('pause')
    } else if (!isPaused.value && !props.isBackgroundVideo) {
        cursorStore.setCursorType('hide-shrink')
    } else if (isPaused.value) {
        cursorStore.setCursorText('Play')
        cursorStore.setCursorType('play')
    } else {
        cursorStore.setCursorType('default')
    }
}

function toggleDefaultCursor() {
    cursorStore.setCursorType('default')
    cursorStore.setCursorText('')
}

function toggleSound() {
    if (muted.value) {
        player.setVolume(1)
    } else {
        player.setVolume(0)
    }
    muted.value = !muted.value
}

const playerSelectorId = computed(() => {
    return props.playerId ? `vimeo-video--${props.playerId}` : 'vimeo-video'
})

watch(
    () => props.forcePlay,
    (newValue) => {
        if (newValue === true) {
            this.playVideo()
        } else {
            this.pauseVideo(true)
        }
    },
)
</script>
<style lang="scss">
.vimeo-video {
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: var(--black-30);
        top: -2%;
        left: -1%;
        position: absolute;
    }
}
.vimeo-video--bg {
    border-radius: 0;

    .vimeo-video__container {
        border-radius: 0;
    }

    .vimeo-video__player {
        position: relative;
        padding-bottom: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        max-width: none;

        iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100vw;
            height: 100vh;
            transform: translate(-50%, -50%);
            pointer-events: none;
        }

        @media (min-aspect-ratio: 16/9) {
            iframe {
                /* height = 100 * (9 / 16) = 56.25 */
                height: 56.25vw;
            }
        }
        @media (max-aspect-ratio: 16/9) {
            iframe {
                /* width = 100 / (9 / 16) = 177.777777 */
                width: 177.78vh;
            }
        }
    }

    .vimeo-video__control-overlay-play-icon--hide {
        display: none;
    }

    .vimeo-video__control-overlay,
    .vimeo-video__controls {
        display: none;
        pointer-events: none;
    }
}

.vimeo-video__container {
    position: relative;
    background-color: var(--black);
    isolation: isolate;
}

.vimeo-video__container-opacitive-overlay {
    @include touchscreen {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: radial-gradient(#000d1a -40%, transparent 120%);
    }
}

.vimeo-video__control-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
}

.vimeo-video__control-overlay-play-icon {
    display: none;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate3d(-50%, -50%, 0);
    transition: transform 0.2s 0s ease;

    @include touchscreen {
        display: flex;
        align-items: center;
    }
}

.vimeo-video__control-overlay-play-icon--playing {
    transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
    transition: transform 0.2s 0s ease;
}

.vimeo-video__control-overlay-play-icon--hide {
    display: none;

    @include touchscreen {
        display: flex;
    }
}

.vimeo-video__player {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.vimeo-video__player--freeflow {
    padding-bottom: 0;
    height: 100%;
}

.vimeo-video__player iframe,
.vimeo-video__player object,
.vimeo-video__player embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.vimeo-video__poster {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(var(--black), 0.2);
    }
}

.vimeo-video__controls {
    display: flex;
    column-gap: 1rem;
    position: absolute;
    z-index: 3;
    bottom: 1.5rem;
    left: 1.5rem;

    @include small-down {
        left: 1rem;
        bottom: 1rem;
    }
}

.vimeo-video__control {
    padding: 0.25rem;

    @include small-down {
        transform: scale(0);
        transition: transform 0.3s ease;
    }

    @include medium-up {
        padding: 0.75rem;
    }
}

.vimeo-video__control-play {
    @include small-down {
        display: none;
    }
}

.vimeo-video--is-playing {
    .vimeo-video__controls {
        @include small-down {
            display: flex;
        }

        .vimeo-video__control-sound {
            @include small-down {
                transform: scale(1);
            }
        }
    }
}
</style>
